import React from 'react';

//SVGs
import img_bga from '../../../../static/svg/quiz-banner-bground-left.svg';
import img_bgb from '../../../../static/svg/quiz-banner-bground-right.svg';

export const InitialPageBanner = ({ activeIcon }) => {
    return (
        <div className="modal_banner d-flex-col align-items-center justify-content-center text-center">
            <img src={activeIcon} alt="Role svg Image" />
            <h2>Want to join our team?</h2>
            <p>You can get a guaranteed interview spot by taking our quiz.</p>
            <div className="modal_banner_bground d-flex-row align-items-center justify-content-between">
                <img src={img_bga} alt="Card Background" />
                <img src={img_bgb} alt="Card Background" />
            </div>
        </div>
    );
};

export default InitialPageBanner;
