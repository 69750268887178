import React from 'react';
import { Spin } from 'antd';

export const Loading = () => {
    return (
        <section className="modal_loading d-flex-col align-items-center justify-content-center gap-3">
            <Spin size="large" />
            <p>Quiz getting ready ...</p>
        </section>
    );
};

export default Loading;
