import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Alert } from 'antd';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

//COGNITO
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

export const InitialPageFormOTP = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListQuiz } = quizContentActions;

    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { mobile } = initial;

    //states
    const [isLoading, setIsLoading] = useState(false);
    const [isValidOTP, setIsValidOTP] = useState(true);
    const [alertMessage, setAlertMessage] = useState('Double check OTP number then try again.');
    const testPassword = 'Test1234.';

    //cognito
    const userPool = useMemo(() => {
        var poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
            ClientId: process.env.REACT_APP_CLIENT_ID ?? '',
        };

        return new CognitoUserPool(poolData);
    }, []);

    //functions
    const onFinish = (values) => {
        //jehu remove
        dispatch(
            updateListQuiz({
                activeComponent: 'multiple',
            }),
        );
        //jehu remove
        const { otp } = values;
        var userData = {
            Username: mobile,
            Pool: userPool,
        };

        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(otp, true, function (err, result) {
            if (err) {
                setAlertMessage(err.message || JSON.stringify(err));
                setIsValidOTP(false);
                return;
            }
            getUser();
        });
    };

    const getUser = () => {
        const userData = {
            Username: mobile,
            Pool: userPool,
        };
        const authenticationDetails = new AuthenticationDetails({
            Username: mobile,
            Password: testPassword,
        });
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                dispatch(
                    updateListQuiz({
                        initial: {
                            activeComponent: 'multiple',
                            token: result.getAccessToken().getJwtToken(),
                        },
                    }),
                );
            },
            onFailure: function (err) {
                console.error(err);
                alert(err.message || JSON.stringify(err));
            },
        });
    };

    //
    const handleChangeNumber = () => {
        setIsValidOTP(true);
        setIsLoading(false);
        dispatch(
            updateListQuiz({
                initial: {
                    activeComponent: 'form',
                },
            }),
        );
    };

    const inputOnChange = () => {
        setIsValidOTP(true);
    };

    return (
        <div className="modal_verify d-flex-col align-items-center justify-content-center">
            <div className="modal_verify_title d-flex-col align-items-center justify-content-center text-center">
                <h2>Just one last step!</h2>
                <p>
                    To verify it’s really you, please enter the verification code that was sent to:{' '}
                    <span>{mobile}</span>
                </p>
            </div>
            <Form name="basic" onFinish={onFinish} layout="vertical">
                {!isValidOTP ? (
                    <Alert
                        message="Something's wrong!"
                        description={alertMessage}
                        type="error"
                        closable
                    />
                ) : (
                    <></>
                )}
                <Form.Item
                    name="otp"
                    rules={[
                        {
                            required: true,
                            message: 'OTP number is required!',
                        },
                    ]}
                >
                    <Input
                        autoComplete="none"
                        placeholder="Enter OTP Number"
                        type="number"
                        onChange={inputOnChange}
                    />
                </Form.Item>
                <Form.Item className="text-center">
                    <Button
                        className="login-button"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={!isValidOTP}
                    >
                        {isLoading ? 'LOADING' : 'VERIFY'}
                    </Button>
                    <Button onClick={handleChangeNumber} type="link" htmlType="reset">
                        Change Number
                    </Button>
                </Form.Item>
            </Form>
            <p className="modal_verify_resend text-center">
                Resend code (60s) <span> 3 retries left </span>
            </p>
        </div>
    );
};

export default InitialPageFormOTP;
