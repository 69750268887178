import React, { useEffect } from 'react';
import contact_hero from '../../../static/svg/contact-hero-e.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const CareerContact = () => {
    useEffect(() => {
        gsap.from('.gsap_contact_career_img ', {
            duration: 0.8,
            y: '10',
            scale: 0.6,
            opacity: 1,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.contact_solutions',
                start: 'top 40%',
                end: 'bottom 50%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
        gsap.from('.gsap_contact_career_text ', {
            duration: 0.8,
            y: '10',
            opacity: 1,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.contact_solutions',
                start: 'top 40%',
                end: 'bottom 50%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);
    //email
    const onClick = () => {
        window.location.href = 'mailto:hello@ziphersolutions.com';
    };

    return (
        <>
            <section
                id="contact"
                className="contact d-flex-col align-items-center justify-content-center contact_solutions"
            >
                <div className="contact_container d-flex-row align-items-center justify-content-center">
                    <img
                        src={contact_hero}
                        alt="Contact Us Hero"
                        className="gsap_contact_career_img"
                    />
                    <div className="contact_container_text d-flex-col gap-1" id="contact-right">
                        <h2 className="gsap_contact_career_text">Ready to join us?</h2>
                        <p className="gsap_contact_career_text">
                            Send us an email with your attached CV. Interested in our job openings?
                            You can also ask for it via email.
                        </p>
                        <h3 className="gsap_contact_career_text" onClick={onClick}>
                            hello@ziphersolutions.com
                        </h3>
                    </div>
                </div>
            </section>
        </>
    );
};
