import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//REDUX
// import { quizContentActions } from '../../store/quiz/QuizReducers';
import { quizContentActions } from '../../redux/reducers/quiz.reducers';

//SUBCOMPONENTS
import { IoCloseSharp } from 'react-icons/io5';
import InitialPage from './subcomponents/InitialPage';
import Loading from './subcomponents/Loading';
import FormMultipleChoice from './subcomponents/FormMultipleChoice';
import FormIdentification from './subcomponents/FormIdentification';
import FormSortable from './subcomponents/FormSortable';
import FormSubmitQuiz from './subcomponents/FormSubmitQuiz';
import FormQuitQuiz from './subcomponents/FormQuitQuiz';

export const QuizMainModal = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { resetStateQuiz, updateListQuiz } = quizContentActions;

    //redux non functions
    const { isOpen, activeComponent, quitConfirm } = useSelector((state) => state.quizContents);

    //functions
    const handleOkModal = () => {
        dispatch(resetStateQuiz());
    };

    const handleConfirm = () => {
        dispatch(
            updateListQuiz({
                quitConfirm: true,
            }),
        );
    };
    const dynamicWidth = () => {
        if (activeComponent === 'initial' && !quitConfirm) {
            return 'width_initial';
        } else {
            return 'width_exam';
        }
    };

    return (
        <>
            <Modal className={`modal_quiz ${dynamicWidth()}`} visible={isOpen} onOk={handleOkModal}>
                <IoCloseSharp onClick={handleConfirm} className="close_main_modal" />
                {!quitConfirm && activeComponent === 'initial' ? <InitialPage /> : null}
                {!quitConfirm && activeComponent === 'loading' ? <Loading /> : null}
                {!quitConfirm && activeComponent === 'multiple' ? <FormMultipleChoice /> : null}
                {!quitConfirm && activeComponent === 'identification' ? (
                    <FormIdentification />
                ) : null}
                {!quitConfirm && activeComponent === 'sortable' ? <FormSortable /> : null}
                {!quitConfirm && activeComponent === 'submit' ? <FormSubmitQuiz /> : null}
                {quitConfirm ? <FormQuitQuiz /> : null}
            </Modal>
        </>
    );
};

export default QuizMainModal;
