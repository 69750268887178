import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//ICON
import icon_roles_a from '../../../../static/svg/career-role-a.svg';
import icon_roles_c from '../../../../static/svg/career-role-c.svg';
import icon_roles_d from '../../../../static/svg/career-role-d.svg';
import icon_roles_e from '../../../../static/svg/career-role-e.svg';

//SUBCOMPONENTS
import InitialPageBanner from './InitialPageBanner';
import InitialPageBannerOTP from './InitialPageBannerOTP';
import InitialPageFormMain from './InitialPageFormMain';
import InitialPageFormOTP from './InitialPageFormOTP';
import InitialPageUserExist from './InitialPageUserExist';

export const InitialPage = () => {
    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { selectedRole, activeComponent } = initial;

    //states
    const [activeIcon, setActiveIcon] = useState(icon_roles_a);

    useEffect(() => {
        if (selectedRole === 'iot') {
            setActiveIcon(icon_roles_c);
        } else if (selectedRole === 'qa') {
            setActiveIcon(icon_roles_d);
        } else if (selectedRole === 'blockchain') {
            setActiveIcon(icon_roles_e);
        } else {
            setActiveIcon(icon_roles_a);
        }
    }, [selectedRole]);

    return (
        <section className="modal_account d-flex-row align-items-center justify-content-center">
            {activeComponent === 'form' || activeComponent === 'userexist' ? (
                <InitialPageBanner activeIcon={activeIcon} />
            ) : (
                <InitialPageBannerOTP />
            )}
            {activeComponent === 'form' ? <InitialPageFormMain /> : null}
            {activeComponent === 'verification' ? <InitialPageFormOTP /> : null}
            {activeComponent === 'userexist' ? <InitialPageUserExist /> : null}
        </section>
    );
};

export default InitialPage;
