import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Alert, message } from 'antd';
import ReactPhoneInput from 'react-phone-number-input';

//COGNITO
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';

//ICON
import icon_roles_a from '../../../../static/svg/career-role-a.svg';
import icon_roles_c from '../../../../static/svg/career-role-c.svg';
import icon_roles_d from '../../../../static/svg/career-role-d.svg';
import icon_roles_e from '../../../../static/svg/career-role-e.svg';
import { IoMail } from 'react-icons/io5';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

export const InitialPageFormMain = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListQuiz } = quizContentActions;

    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { selectedRole } = initial;

    //states
    const [activeIcon, setActiveIcon] = useState(icon_roles_a);
    const [activeRole, setActiveRole] = useState('Front-end Developer');
    const [isLoading, setIsLoading] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertDesc, setAlertDesc] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const testPassword = 'Test1234.';

    //functions
    const closeAlert = () => {
        setIsEmail(true);
    };

    //cognito
    const userPool = useMemo(() => {
        var poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
            ClientId: process.env.REACT_APP_CLIENT_ID ?? '',
        };

        return new CognitoUserPool(poolData);
    }, []);

    const onFinish = (values) => {
        setIsLoading(true);
        //states
        const { email, mobile } = values;
        var attributeList = [];
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        //email validation
        if (email.match(mailformat)) {
            setIsEmail(true);
        } else {
            setIsEmail(false);
        }
        //mobile validation
        if (mobile.length >= 14) {
            setIsMobile(false);
            setAlertDesc('Mobile number is too long. Check input to continue.');
        } else if (mobile.length <= 12) {
            console.log(mobile.length);
            setIsMobile(false);
            setAlertDesc('Mobile number is too Short. Check input to continue.');
        }
        //API Call start
        if (email.match(mailformat) && mobile.length == 13) {
            const attributePhoneNumber = new CognitoUserAttribute({
                Name: 'phone_number',
                Value: mobile,
            });
            const attributeEmail = new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            });

            attributeList.push(attributeEmail);
            attributeList.push(attributePhoneNumber);

            userPool.signUp(mobile, testPassword, attributeList, [], function (err, result) {
                if (err) {
                    // message.warning(err.message || JSON.stringify(err));
                    dispatch(
                        updateListQuiz({
                            initial: {
                                activeComponent: 'userexist',
                            },
                        }),
                    );
                    setIsLoading(false);
                    return;
                }
                var cognitoUser = result.user;
                dispatch(
                    updateListQuiz({
                        initial: {
                            activeComponent: 'verification',
                            mobile: mobile,
                            email,
                            cognitoUser,
                        },
                    }),
                );
                setIsLoading(false);
            });
        }
    };

    const onCloseModal = () => {
        dispatch(
            updateListQuiz({
                quitConfirm: true,
            }),
        );
    };

    useEffect(() => {
        inputOnChange();
    }, [phoneValue]);

    const inputOnChange = (e) => {
        setIsEmail(true);
        setIsMobile(true);
    };

    useEffect(() => {
        if (selectedRole === 'frontend') {
            setActiveRole('Front-end Developer');
        } else if (selectedRole === 'backend') {
            setActiveRole('Back-end Developer');
        } else if (selectedRole === 'iot') {
            setActiveRole('IoT Engineer');
            setActiveIcon(icon_roles_c);
        } else if (selectedRole === 'qa') {
            setActiveRole('QA Engineer');
            setActiveIcon(icon_roles_d);
        } else if (selectedRole === 'blockchain') {
            setActiveRole('Blockchain Developer');
            setActiveIcon(icon_roles_e);
        } else {
            setActiveIcon(icon_roles_a);
        }
    }, [selectedRole]);

    useEffect(() => {
        if (!isEmail && !isMobile) {
            setIsLoading(false);
            setAlertMessage('Invalid Email and Mobile number!');
            setAlertDesc('Please input a valid email and mobile number.');
        } else if (!isEmail && isMobile) {
            setIsLoading(false);
            setAlertMessage('Invalid Email Address!');
            setAlertDesc('Please input a valid email address.');
        } else if (isEmail && !isMobile) {
            setIsLoading(false);
            setAlertMessage('Invalid Mobile Number!');
        }
    }, [isEmail, isMobile]);

    return (
        <div className="modal_initial d-flex-col align-items-center justify-content-center">
            <div className="modal_initial_title d-flex-col align-items-center justify-content-center text-center">
                <img src={activeIcon} alt="Role svg Image" />
                <p>
                    You have selected the <span> {activeRole} </span> role. <br /> The questions in
                    this quiz will all be related to {activeRole}.
                </p>
            </div>
            <Form name="basic" onFinish={onFinish} layout="vertical">
                {!isEmail || !isMobile ? (
                    <Alert
                        message={alertMessage}
                        description={alertDesc}
                        type="error"
                        closable
                        onClose={closeAlert}
                    />
                ) : null}
                <p className="text-center">
                    To maintain security, we require users to enter their email and mobile number to
                    verify them as well.
                </p>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Requires a valid email address!',
                        },
                    ]}
                >
                    <Input
                        autoComplete="none"
                        onChange={inputOnChange}
                        placeholder="Enter your Email"
                        prefix={<IoMail />}
                    />
                </Form.Item>
                <Form.Item
                    name="mobile"
                    className="react_phone"
                    rules={[
                        {
                            required: true,
                            message: 'Requires a valid mobile number!',
                        },
                    ]}
                >
                    <ReactPhoneInput
                        required
                        autoComplete="none"
                        id="mobileInput"
                        defaultCountry="PH"
                        placeholder="9876543210"
                        value={phoneValue}
                        onChange={setPhoneValue}
                    />
                </Form.Item>
                <Form.Item className="text-center">
                    <Button
                        className="login-button"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={!isEmail}
                    >
                        {isLoading ? 'LOADING' : 'CONTINUE'}
                    </Button>
                    <Button onClick={onCloseModal} type="link" htmlType="reset">
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default InitialPageFormMain;
