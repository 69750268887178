import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import hero from '../../../../static/svg/modal-hero-user-exist.svg';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

export const InitialPageUserExist = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { resetStateQuiz } = quizContentActions;

    const handleQuit = () => {
        dispatch(resetStateQuiz());
    };

    return (
        <div className="modal_user_exist d-flex-col align-items-center justify-content-center text-center">
            <div className="modal_user_exist_content d-flex-col align-items-center justify-content-center text-center">
                <img src={hero} alt="Zipher Solutions Hero" />
                <h2>Thank you for your interest!</h2>
                <p>
                    It seems like the details you have entered match with an ongoing application
                    currently being checked by our team. We will contact you once it has been
                    reviewed.
                </p>
                <Button onClick={handleQuit}>Got it</Button>
            </div>
        </div>
    );
};

export default InitialPageUserExist;
