import React, { useState, useEffect } from 'react';
import { Radio, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//SVGs
import svg_footer from '../../../../static/svg/quiz-svg-footer.svg';
import quiz_bullet from '../../../../static/svg/quiz-radio-bullet.svg';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

export const FormMultipleChoice = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListQuiz } = quizContentActions;

    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { selectedRole } = initial;
    //states
    const [selctedRadio, setSelectedRadio] = useState(1);
    const [activeRole, setActiveRole] = useState('Front-end Developer');

    const optionOnChange = (item) => {
        setSelectedRadio(item.target.value);
    };

    const nextPage = () => {
        dispatch(
            updateListQuiz({
                activeComponent: 'identification',
            }),
        );
    };

    useEffect(() => {
        if (selectedRole === 'frontend') {
            setActiveRole('Front-end Developer');
        } else if (selectedRole === 'backend') {
            setActiveRole('Back-end Developer');
        } else if (selectedRole === 'iot') {
            setActiveRole('IoT Engineer');
        } else if (selectedRole === 'qa') {
            setActiveRole('QA Engineer');
        } else if (selectedRole === 'blockchain') {
            setActiveRole('Blockchain Developer');
        } else {
        }
    }, [selectedRole]);

    const radioOptions = [
        {
            title: 'Option A',
            subtitle:
                'Lorem ipsum dolor sit amet. Sit ratione optio et officiis vero vel pariatur est.',
        },
        {
            title: 'Option B',
            subtitle:
                'Lorem ipsum dolor sit amet. Sit ratione optio et officiis vero vel pariatur est.',
        },
        {
            title: 'Option C',
            subtitle:
                'Lorem ipsum dolor sit amet. Sit ratione optio et officiis vero vel pariatur est.',
        },
        {
            title: 'Option D',
            subtitle:
                'Lorem ipsum dolor sit amet. Sit ratione optio et officiis vero vel pariatur est.',
        },
    ];

    return (
        <section className="modal_multiple d-flex-col align-items-center justify-content-center">
            <div className="modal_multiple_title">
                <h3>{activeRole} Puzzle</h3>
                <p>
                    Solve these series of {activeRole} puzzles and you will be qualified to an
                    interview to join our team.
                </p>
            </div>
            <div className="modal_multiple_content d-flex-row gap-2">
                <div className="modal_multiple_content_banner"></div>
                <div className="modal_multiple_content_question flex-1 d-flex-col justify-content-between">
                    <div className="modal_multiple_content_question_text">
                        <p>Puzzle 1: Choose the Correct Answer</p>
                        <p>
                            Describe the question or puzzle here. It will be an IoT related question
                            and it can be an objective type, multiple choice, or an interactive type
                            like a circuit/device builder or even a mystery type of game.
                        </p>
                        <Radio.Group
                            defaultValue={1}
                            onChange={optionOnChange}
                            value={selctedRadio}
                        >
                            {radioOptions.map((opt, i) => (
                                <Radio key={i} value={i + 1}>
                                    <div className="radio_opt_item d-flex-row align-items-center justify-content-center">
                                        <img src={quiz_bullet} alt="Quiz bullet" />
                                        <div className="d-flex-col">
                                            <p>{opt.title}</p>
                                            <p>{opt.subtitle}</p>
                                        </div>
                                    </div>
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                    <div className="modal_multiple_content_question_footer d-flex-row align-items-center justify-content-between">
                        <div className="d-flex-row align-items-center gap-1">
                            <img src={svg_footer} alt="SVG Icon" />
                            <p>Mention that clues and hints are placed randomly in the website.</p>
                        </div>
                        <Button type="primary" onClick={nextPage}>
                            Next Puzzle
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormMultipleChoice;
