import React, { useEffect } from 'react';
import hero_a from '../../../static/svg/career-setup-a.svg';
import hero_b from '../../../static/svg/career-setup-b.svg';
import hero_c from '../../../static/svg/career-setup-c.svg';
import background_title from '../../../static/svg/solutions-title-background.svg';
import img_bga from '../../../static/svg/roles-bground-left.svg';
import img_bgb from '../../../static/svg/roles-bground-right.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const CareerSetup = () => {
    useEffect(() => {
        gsap.from('.gsap_setup_career_text', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '#careersetup',
                start: 'top 40%',
                end: 'bottom 30%',
                // markers: true,
                toggleActions: 'play none none reverse',
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section
                id="careersetup"
                className="careersetup d-flex-col align-items-center justify-content-center"
            >
                <div className="careersetup_container d-flex-col align-items-center justify-content-center">
                    <div className="careersetup_container_title text-center">
                        <p>SETUP</p>
                        <h2>What it’s like in Zipher</h2>
                        <img src={background_title} alt="Title Background" />
                    </div>
                    <div className="careersetup_container_content d-flex-col align-items-center justify-content-center">
                        {/* HERO 1 */}
                        <div className="careersetup_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_a} alt="About us Hero" />
                            </div>
                            <div className="gsap_setup_career_text myhero_text d-flex-col gap-1">
                                <p>Remote Work</p>
                                <p>
                                    Enjoy the luxury of <span> working from home</span> anywhere.
                                </p>
                                <p></p>
                            </div>
                        </div>
                        {/* HERO 2 */}
                        <div className="careersetup_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="gsap_setup_career_text myhero_text d-flex-col gap-1">
                                <p>Agile Management</p>
                                <p>
                                    Our project management and software development approaches are
                                    based on the <span>industry standards</span> of the{' '}
                                    <span>Agile framework</span>. Allowing us to deliver
                                    high-quality products with <span>outstanding customer satisfaction</span>.
                                </p>
                                <p></p>
                            </div>
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_b} alt="About us Hero" />
                            </div>
                        </div>
                        {/* HERO 3 */}
                        <div className="careersetup_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_c} alt="About us Hero" />
                            </div>
                            <div className="gsap_setup_career_text myhero_text d-flex-col gap-1">
                                <p>Project Based</p>
                                <p>
                                    We are currently on a project-based operation but we’re aiming
                                    to <span>run Zipher Solutions full-time</span>. All developers,
                                    part time/ full time will be assigned to a project.
                                </p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="careersetup_bground d-flex-row align-items-center justify-content-between">
                    <img src={img_bga} alt="Card Background" />
                    <img src={img_bgb} alt="Card Background" />
                </div>
            </section>
        </>
    );
};
