import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';

// icons
import icon_roles_a from '../../../static/svg/career-role-a.svg';
import icon_roles_b from '../../../static/svg/career-role-b.svg';
import icon_roles_c from '../../../static/svg/career-role-c.svg';
import icon_roles_d from '../../../static/svg/career-role-d.svg';
import icon_roles_e from '../../../static/svg/career-role-e.svg';
import background_title from '../../../static/svg/solutions-title-background.svg';
// import { AiFillBulb } from 'react-icons/ai';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

//REDUX
// import { quizContentActions } from '../../redux/reducers/quiz.reducers';

//SUBCOMPONENT
import QuizMainModal from '../modal/QuizMainModal';

export const CareerRoles = () => {
    //redux
    // const dispatch = useDispatch();

    //functions
    // const handleClickRole = (key) => {
    //     dispatch(
    //         quizContentActions.updateListQuiz({
    //             isOpen: true,
    //             initial: {
    //                 selectedRole: key,
    //             },
    //         }),
    //     );
    // };

    //gsap action
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        gsap.from('.gsap_role_careers_img ', {
            duration: .2,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.roles_careers',
                start: 'top 50%',
                end: 'bottom 30%',
                // markers: true,
                toggleActions: 'play none none reverse',
                delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);

    const roles_cards = [
        {
            key: 'frontend',
            img: icon_roles_a,
            alt: 'Role Img',
            title: 'Front-end Developer',
            desc: (
                <>
                    Do you have a solid background in web or mobile app development? We need
                    developers who can develop solutions using the latest technologies. ReactJS
                    knowledge is an advantage!
                </>
            ),
        },
        {
            key: 'backend',
            img: icon_roles_b,
            alt: 'Role Img',
            title: 'Back-end Developer',
            desc: (
                <>
                    Are you an expert in AWS Services or Node.js with a strong understanding of OOP
                    concepts? We're eager to know you.
                </>
            ),
        },
        {
            key: 'iot',
            img: icon_roles_c,
            alt: 'Role Img',
            title: 'IoT Engineer',
            desc: (
                <>
                    Are IoT devices, systems and networks your specialty? You may be what we're
                    looking for!
                </>
            ),
        },
        {
            key: 'qa',
            img: icon_roles_d,
            alt: 'Role Img',
            title: 'QA Engineer',
            desc: (
                <>
                    Are you knowledgeable in QA testing methodologies? We're looking for someone who
                    can ensure we only deliver high-quality solutions. Experience in regression and
                    automation testing is a big plus!
                </>
            ),
        },
        {
            key: 'blockchain',
            img: icon_roles_e,
            alt: 'Role Img',
            title: 'Blockchain Developer',
            desc: (
                <>
                    Do you have experience in cryptocurrency/blockchain development and a solid
                    background in data structures & algorithm? We're interested to meet you!
                </>
            ),
        },
    ];

    return (
        <>
            <section className="careerroles d-flex-col align-items-center justify-content-center roles_careers">
                <div className="careerroles_container  d-flex-col align-items-center justify-content-center">
                    <div className="careerroles_container_title text-center">
                        <p>ROLES</p>
                        <h2>Level up your career</h2>
                        <img src={background_title} alt="Title Background" />
                    </div>
                    <div className="careerroles_container_cards">
                        {roles_cards.map((card) => (
                            <div
                                key={card.key}
                                // onClick={() => handleClickRole(card.key)}
                                className="careerroles_container_cards_card d-flex-col align-items-center justify-content-center text-center"
                            >
                                <img
                                    className="gsap_role_careers_img"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                    }}
                                    src={card.img}
                                    alt={card.alt}
                                />
                                <div className="text d-flex-col">
                                    <p className='card_p'>{card.title}</p>
                                    <p className='card_p'>{card.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="careerroles_container_message d-flex-row align-items-center gap-1">
                        <AiFillBulb />
                        <p>
                            Interested in joining us? You can have a spot in our job interview!
                            Click the role you want to join to find out how.
                        </p>
                    </div> */}
                </div>
            </section>
            <QuizMainModal />
        </>
    );
};
