import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

//SVGs
import quiz_bullet from '../../../../static/svg/quiz-radio-bullet.svg';
import dragable from '../../../../static/svg/draggable.svg';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const FormSortablePlugin = () => {
    const [option, setOption] = useState([
        { id: 1, name: 'Item A' },
        { id: 2, name: 'Item B' },
        { id: 3, name: 'Item C' },
        { id: 4, name: 'Item D' },
    ]);

    return (
        <ReactSortable
            className="draggable_component_container d-flex-row align-items-center justify-content-between"
            list={option}
            setList={setOption}
        >
            {option.map((item) => (
                <div
                    key={item.id}
                    className="draggable_item_container d-flex-row align-items-center"
                >
                    <div className="draggable_item d-flex-row align-items-center">
                        <div className="d-flex-row align-items-center gap-1">
                            <img src={quiz_bullet} alt="Bullet Icon" />
                            <p> {item.name}</p>
                        </div>
                        <img src={dragable} alt="Drag Icon" />
                    </div>
                    <AiOutlineArrowRight className="arrow" />
                </div>
            ))}
        </ReactSortable>
    );
};

export default FormSortablePlugin;
