import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//SVGs
import confirmIcon from '../../../../static/svg/modal-submit-icon.svg';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

export const FormSubmitQuiz = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListQuiz, resetStateQuiz } = quizContentActions;

    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { selectedRole } = initial;

    //states
    const [isSubmitted, setIsSubmitted] = useState(false);

    //functions
    const handleHandleDone = () => {
        dispatch(resetStateQuiz());
        setIsSubmitted(false);
    };
    const handleSubmit = () => {
        setIsSubmitted(true);
    };
    const reviewAnswer = () => {
        dispatch(
            updateListQuiz({
                activeComponent: 'multiple',
            }),
        );
    };

    return (
        <section className="modal_confirm d-flex-col align-items-center justify-content-center">
            <div className="modal_confirm_title d-flex-col align-items-center justify-content-center text-center">
                <img src={confirmIcon} alt="Role svg Image" />
                {isSubmitted ? (
                    <>
                        <h2 className="confirm_subtitle">Submission Complete!</h2>
                        <p>
                            Thank you for participating. We will verify your answers and give you a
                            feedback sent via email to let you know if you have been qualified to
                            join our team.
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className="confirm_subtitle">Ready to submit your answers?</h2>
                        <p>
                            Click the submit button to finish the quiz or you can go back to review
                            your answers as well.
                        </p>
                    </>
                )}
            </div>
            <div className="modal_confirm_actions d-flex-col gap-1">
                {isSubmitted ? (
                    <Button type="primary" onClick={handleHandleDone}>
                        GOT IT!
                    </Button>
                ) : (
                    <>
                        <Button type="primary" onClick={handleSubmit}>
                            SUBMIT
                        </Button>
                        <Button type="primary" onClick={reviewAnswer}>
                            REVIEW ANSWER
                        </Button>
                    </>
                )}
            </div>
        </section>
    );
};

export default FormSubmitQuiz;
