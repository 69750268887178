import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

//REDUX
import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

//SVGs
import svg_footer from '../../../../static/svg/quiz-svg-footer.svg';

export const FormIdentification = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { updateListQuiz } = quizContentActions;

    //redux non functions
    const { initial } = useSelector((state) => state.quizContents);

    //::
    const { selectedRole } = initial;

    //states
    const { TextArea } = Input;
    const [activeRole, setActiveRole] = useState('Front-end Developer');

    //
    const nextPage = () => {
        dispatch(
            updateListQuiz({
                activeComponent: 'sortable',
            }),
        );
    };
    const previousPage = () => {
        dispatch(
            updateListQuiz({
                activeComponent: 'multiple',
            }),
        );
    };

    useEffect(() => {
        if (selectedRole === 'frontend') {
            setActiveRole('Front-end Developer');
        } else if (selectedRole === 'backend') {
            setActiveRole('Back-end Developer');
        } else if (selectedRole === 'iot') {
            setActiveRole('IoT Engineer');
        } else if (selectedRole === 'qa') {
            setActiveRole('QA Engineer');
        } else if (selectedRole === 'blockchain') {
            setActiveRole('Blockchain Developer');
        } else {
        }
    }, [selectedRole]);

    return (
        <section className="modal_identify d-flex-col align-items-center justify-content-center">
            <div className="modal_identify_title">
                <h3>{activeRole} Puzzle</h3>
                <p>
                    Solve these series of {activeRole} puzzles and you will be qualified to an
                    interview to join our team.
                </p>
            </div>
            <div className="modal_identify_content d-flex-row gap-2">
                <div className="modal_identify_content_banner"></div>
                <div className="modal_identify_content_question flex-1 d-flex-col justify-content-between">
                    <div className="modal_identify_content_question_text">
                        <p>Puzzle 2: Decode Image</p>
                        <p>
                            Describe the question or puzzle here. It will be an IoT related question
                            and it can be an objective type, multiple choice, or an interactive type
                            like a circuit/device builder or even a mystery type of game.
                        </p>
                        <TextArea rows={9} placeholder="Answer here..." />
                    </div>
                    <div className="modal_identify_content_question_footer d-flex-row align-items-center justify-content-between gap-1">
                        <div className="d-flex-row align-items-center gap-1">
                            <img src={svg_footer} alt="SVG Icon" />
                            <p>Hint: I am some hint</p>
                        </div>
                        <div className="d-flex-row align-items-center gap-1">
                            <Button type="primary" onClick={previousPage}>
                                Previous
                            </Button>
                            <Button type="primary" onClick={nextPage}>
                                Next Puzzle
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormIdentification;
