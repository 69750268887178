import React, { useEffect } from 'react';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import img_bgb from '../../../static/svg/roles-bground-right.svg';

gsap.registerPlugin(ScrollTrigger);

export const CareerBanner = () => {
    useEffect(() => {
        gsap.from('.gsap_banner', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.careerbanner',
                start: 'top 100%',
                end: 'bottom 30%',
                toggleActions: 'play',
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section
                className="careerbanner d-flex-col align-items-center justify-content-center"
                name="banner"
            >
                <div className="careerbanner_main d-flex-col align-items-center justify-content-center">
                    {/* <img src={banner_img} alt='Banner Img Background' /> */}
                    <p className="gsap_banner display_3 text-center">
                        Join us in
                        <span className="text-orange"> Zipher Solutions </span>
                    </p>
                    {/* <p className="gsap_banner text-center text-gray">
                        Describe what it is like to be in Zipher. What kind of people are we? <br />{' '}
                        Keep this phrase two to three lines.
                    </p> */}
                </div>
                <div>
                    <img src={img_bgb} alt="Card Background" className='aboutbanner-shape-1' />
                    <img src={img_bgb} alt="Card Background" className='aboutbanner-shape-2' />
                </div>
            </section>
        </>
    );
};
