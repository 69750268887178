import React from 'react';
import card_bground from '../../../static/svg/showcase-bground.svg';
import card_bground_mobile from '../../../static/svg/showcase-bground-mobile.svg';
import cup from '../../../static/svg/core-cup.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const CareerTopRated = () => {
    const showcase = [
        {
            int: 'TOP RATED',
            sub: 'Upwork badge recognizing exellency and quality in work.',
        },
        {
            int: '95%',
            sub: 'Job Success Score on Upwork',
        },
    ];

    return (
        <>
            <section className="toprated">
                <div className="cards_container d-flex-row align-items-center justify-content-center gap-3">
                    {showcase.map((card, i) => (
                        <div key={i} className="card">
                            <div className="title">
                                <h2>{card.int}</h2>
                                <p>{card.sub}</p>
                            </div>
                            <img src={card_bground} alt="Card Background" />
                        </div>
                    ))}
                </div>
                <div className="cards_container_mobile d-flex-row align-items-center justify-content-center">
                    <div className="card">
                        <div className="title">
                            {showcase.map((card, i) => (
                                <div
                                    key={i}
                                    className="title_group d-flex-row align-items-center gap-1"
                                >
                                    <img src={cup} alt="Zipher Cup Img" />
                                    <div className="d-flex-col justify-content-center">
                                        <h2>{card.int}+</h2>
                                        <p>{card.sub}</p>
                                    </div>
                                    <div className="line"></div>
                                </div>
                            ))}
                        </div>
                        <img src={card_bground_mobile} alt="Card Background" />
                    </div>
                </div>
            </section>
        </>
    );
};
