import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { quizContentActions } from '../../../redux/reducers/quiz.reducers';

export const FormQuitQuiz = () => {
    //redux
    const dispatch = useDispatch();

    //redux functions
    const { resetStateQuiz, updateListQuiz } = quizContentActions;

    //
    const handleContinue = () => {
        dispatch(
            updateListQuiz({
                quitConfirm: false,
            }),
        );
    };
    const handleQuit = () => {
        dispatch(resetStateQuiz());
    };

    return (
        <section className="modal_quit d-flex-col align-items-center justify-content-center">
            <div className="modal_confirm_title d-flex-col align-items-center justify-content-center text-center">
                <h2>Quit the quiz?</h2>
                <p>
                    Your answers will not be submitted and <br /> you will have to retry another set
                    of questions if you restart.
                </p>
            </div>
            <div className="modal_confirm_actions_confirm d-flex-col gap-1">
                <Button onClick={handleContinue}>CONTINUE</Button>
                <Button onClick={handleQuit}>CLOSE & QUIT</Button>
            </div>
        </section>
    );
};

export default FormQuitQuiz;
