import React from 'react';

import bannerOtp from '../../../../static/svg/modal-hero-otp.svg';

export const InitialPageBannerOTP = () => {
    return (
        <section className="modal_banner_otp">
            <img src={bannerOtp} alt="Banner Hero" />
        </section>
    );
};

export default InitialPageBannerOTP;
